body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.loader-page{
  height: 100%;
  width: 100%;
  background-color: white;
  top:0px;
  left:0px;
  position:fixed;
  z-index: 500;
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to { 
      transform: rotate(360deg);
  }
}


@-webkit-keyframes rotate {
  from {
      -webkit-transform: rotate(0deg);
  }
  to { 
      -webkit-transform: rotate(360deg);
  }
}

.loader {
width: 40px;
height: 40px;
position: absolute;
top: calc(50% - 40px);
left: calc(50% - 40px);
border:solid 4px #3498db;
border-radius: 50%;
border-right-color: transparent;
border-bottom-color: transparent;
-webkit-animation-name:             rotate; 
-webkit-animation-duration:         1.0s; 
-webkit-animation-iteration-count:  infinite;
-webkit-animation-timing-function: linear;   
animation-name:             rotate; 
animation-duration:         1.0s; 
animation-iteration-count:  infinite;
animation-timing-function: linear; 
}



.loader--hide {
  opacity: 0;
  height: 0px;
  width: 0px;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


body.waiting * { cursor: wait; }
