.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.77);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 

  .loading-animation {
    width: 50px;
    height: 50px;
    position: relative;
  }
  
  .loading-animation:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid #e5e5e5;
  }
  
  .loading-animation:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: #007aff;
    animation: fill 1s linear infinite;
  }
  
  @keyframes fill {
    0% {
      transform: rotate(0deg);
      border-top-color: #007aff;
    }
    50% {
      transform: rotate(180deg);
      border-top-color: transparent;
    }
    100% {
      transform: rotate(360deg);
      border-top-color: #007aff;
    }
  }