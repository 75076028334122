.sendButton {
  margin-top: 200px;
  margin-left: 30px;
}
.previousButton {
  margin-top: 20px;
  color: #FFFF;
}

.errorAlert {
  font-family: 'Zen Antique Soft';
  font-size: '5px';
}
.mCS-rounded-dark-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
  background-color: red!important; /*comment*/
}

.mCSB_scrollTools {
  margin-right: 8px;
  margin-bottom: 10px;
  margin-top: 9px;
}

p.textNoir{
  font-family: 'Zen Antique Soft';
  font-size: 18px;
}

p.textBlanc{
  color: #FFFF;
  font-family: 'Zen Antique Soft';
  font-size: 18px;
  margin-bottom: 15px;
  text-align: center;
}

.paper {
  width: 100%;
  background-color: white;
  padding-bottom: 20px;
  padding-top: 35px;
  border-radius:4%;
  padding-left: 40px;
}

div.alert {
  font-family: 'Zen Antique Soft';
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: 20px;
}

p.formText{
  font-family: 'Zen Antique Soft';
  font-size: 17px;
}

div.formLine{
  margin-bottom: 15px;
}

  
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  font-size: 18px!important;
  border-radius: 0%!important;
  border-color: black!important;
  border-width: thick!important;

}

.MuiButton-root {
  font-family:'Ubuntu'!important;
}
.MuiTypography-caption{
  font-family:'Ubuntu'!important;
}
.MuiAlert-icon{
  margin-top: 4px!important;
}

.css-ptiqhd-MuiSvgIcon-root{
  position: relative;
  top: 10%;
}

