@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

@media only screen and (min-width: 1515px){
    .listTitles{
        font-size: 2vh!important;
        font-family: 'Kanit';
        
    }
    .listPatients{
        font-size: 2vh!important;
        font-family: 'Kanit';

    }
    .listCibles{
        font-size: 1.7vh!important;
        font-family: 'Kanit';
    }
    .list_cbile_description{
        font-size: 1.3vh!important;
        font-family: 'Kanit';
    }
    .localisation{
        font-size: 2.7vh!important;
        font-family: 'Kanit';
    }
    .affichage_text{
        font-size: 1.2vh!important;
        font-family: 'Kanit';
    }
    .svg_icons {
        transform: scale(1.2);
      }
 
}

@media (min-width: 1360px) and (max-width: 1515px) {
    .listTitles{
        font-size: 1.8vh!important;
        font-family: 'Kanit';
    }
    .listPatients{
        font-size: 1.95vh!important;
        font-family: 'Kanit';
    }
    .listCibles{
        font-size: 1.65vh!important;
        font-family: 'Kanit';
    }
    .list_cbile_description{
        font-size: 1.25vh!important;
        font-family: 'Kanit';
    }
    .localisation{
        font-size: 2.6vh!important;
        font-family: 'Kanit';
    }
    .svg_icons {
        transform: scale(1.12);
      }
    .affichage_text{
        font-size: 1.15vh!important;
        font-family: 'Kanit';
    }
}

@media (min-width: 1185px) and (max-width: 1360px) {
    .listTitles{
        font-size: 1.7vh!important;
        font-family: 'Kanit';
    }
    .listPatients{
        font-size: 1.9vh!important;
        font-family: 'Kanit';
    }
    .listCibles{
        font-size: 1.6vh!important;
        font-family: 'Kanit';
    }
    .list_cbile_description{
        font-size: 1.2vh!important;
        font-family: 'Kanit';
    }
    .localisation{
        font-size: 2.5vh!important;
        font-family: 'Kanit';
    }
    .svg_icons {
        transform: scale(0.95);
      }
    .affichage_text{
        font-size: 1.05vh!important;
        font-family: 'Kanit';
    }

}


@media screen and (min-width: 900px) and (max-width: 1185px){
    .listTitles{
        font-size: 1.6vh!important;
        font-family: 'Kanit';
    }
    .listPatients{
        font-size: 1.85vh!important;
        font-family: 'Kanit';
    }
    .listCibles{
        font-size: 1.55vh!important;
        font-family: 'Kanit';
    }
    .list_cbile_description{
        font-size: 1.15vh!important;
        font-family: 'Kanit';
    }
    .localisation{
        font-size: 2.4vh!important;
        font-family: 'Kanit';
    }
    .affichage_text{
        font-size: 1.2vh!important;
        font-family: 'Kanit';
    }
    .svg_icons {
        transform: scale(0.9);
      }

}

@media only screen  and (max-width: 900px)  {
    .listTitles{
        font-size: 1.5vh!important;
        font-family: 'Kanit';
    }
    .listPatients{
        font-size: 1.8vh!important;
        font-family: 'Kanit';
    }
    .listCibles{
        font-size: 1.5vh!important;
        font-family: 'Kanit';
    }
    .list_cbile_description{
        font-size: 1.1vh!important;
        font-family: 'Kanit';
    }
    .localisation{
        font-size: 2.3vh!important;
        font-family: 'Kanit';
    }
    .affichage_text{
        font-size: 1.2vh!important;
        font-family: 'Kanit';
    }
    .svg_icons {
        transform: scale(0.85);
      }

}

@media only screen  and (max-width: 1000px){
   .selectVue   {
    width: 10vh!important;
   }

}




 @media (max-height: 750px) and (min-height: 614px) {
    .listTitles{
        font-size: 1.5vh!important;
        font-family: 'Kanit';
    }
    .listPatients{
        font-size: 1.75vh!important;
        font-family: 'Kanit';
    }
    .listCibles{
        font-size: 1.45vh!important;
        font-family: 'Kanit';
    }
    .list_cbile_description{
        font-size: 1.05vh!important;
        font-family: 'Kanit';
    }
    .localisation{
        font-size: 2.2vh!important;
        font-family: 'Kanit';
    }
    .affichage_text{
        font-size: 1.2vh!important;
        font-family: 'Kanit';
    }
    .svg_icons {
        transform: scale(0.8);
      }
 
 }

 @media (max-height: 614px) and (min-height: 350px) {
    .listTitles{
        font-size: 1.4vh!important;
        font-family: 'Kanit';
    }
    .listPatients{
        font-size: 1.7vh!important;
        font-family: 'Kanit';
    }
    .listCibles{
        font-size: 0.85vh!important;
        font-family: 'Kanit';
    }
    .list_cbile_description{
        font-size: 1vh!important;
        font-family: 'Kanit';
    }
    .localisation{
        font-size: 2.1vh!important;
        font-family: 'Kanit';
    }
    .affichage_text{
        font-size: 1.2vh!important;
        font-family: 'Kanit';
    }
    .svg_icons {
        transform: scale(0.75);
      }
 
 }


.text-view-3d{
    text-transform: uppercase;
    background-image: linear-gradient(
      -100deg,
      #0000 0%,
      #fff 100%
    );
    color: #fff;
    background-clip: text;
    background-size: 200% auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    font-family: 'Kanit';
  }
  
  @keyframes textclip {
    to {
      background-position: -200% center;
    }
  }

.allViews-cursor{
    cursor: wait;

}
  
.fadein {
   
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.fadein load {
    opacity: 1;
}
/*#crop{
    margin-top: -75px;
}*/
#crop{
    margin-top: -200px;
}

*, *:before, *:after {
    -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
   }
  
  
  .mouse_scroll {
      display: block;
      margin: 0 auto;
      width: 24px;
      height: 100px;
      margin-top: 125px;
  }
  
  
  .m_scroll_arrows
  {
    display: block;
    width: 5px;
    height: 5px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
     
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    margin: 0 0 3px 4px;
    
    width: 16px;
    height: 16px;
  }
  
  
  .unu
  {
    margin-top: 1px;
  }
  
  .unu, .doi, .trei
  {
      -webkit-animation: mouse-scroll 1s infinite;
      -moz-animation: mouse-scroll 1s infinite;
      animation: mouse-scroll 1s infinite;
    
  }
  
  .unu
  {
    -webkit-animation-delay: .1s;
    -moz-animation-delay: .1s;
    -webkit-animation-direction: alternate;
    
    animation-direction: alternate;
    animation-delay: alternate;
  }
  
  .doi
  {
    -webkit-animation-delay: .2s;
    -moz-animation-delay: .2s;
    -webkit-animation-direction: alternate;
    
    animation-delay: .2s;
    animation-direction: alternate;
    
    margin-top: -6px;
  }
  
  .mouse_scroll:hover {
    opacity: .5;
  }
  
  .trei
  {
    -webkit-animation-delay: .3s;
    -moz-animation-delay: .3s;
    -webkit-animation-direction: alternate;
    
    animation-delay: .3s;
    animation-direction: alternate;
    
    
    margin-top: -6px;
  }
  
  .mouse {
    height: 42px;
    width: 24px;
    border-radius: 14px;
    transform: none;
    border: 2px solid white;
    top: 170px;
  }
  
  .wheel {
    height: 5px;
    width: 2px;
    display: block;
    margin: 5px auto;
    background: white;
    position: relative;
    
    height: 4px;
    width: 4px;
    border: 2px solid #fff;
    -webkit-border-radius: 8px;
            border-radius: 8px;
  }
  
  .wheel {
    -webkit-animation: mouse-wheel 0.6s linear infinite;
    -moz-animation: mouse-wheel 0.6s linear infinite;
    animation: mouse-wheel 0.6s linear infinite;
  }
  
  @-webkit-keyframes mouse-wheel{
     0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translateY(6px);
      -ms-transform: translateY(6px);
      transform: translateY(6px);
    }
  }
  @-moz-keyframes mouse-wheel {
    0% { top: 1px; }
    25% { top: 2px; }
    50% { top: 3px;}
    75% { top: 2px;}
    100% { top: 1px;}
  }
  @-o-keyframes mouse-wheel {
  
     0% { top: 1px; }
    25% { top: 2px; }
    50% { top: 3px;}
    75% { top: 2px;}
    100% { top: 1px;}
  }
  @keyframes mouse-wheel {
  
     0% { top: 1px; }
    25% { top: 2px; }
    50% { top: 3px;}
    75% { top: 2px;}
    100% { top: 1px;}
  }
  
  @-webkit-keyframes mouse-scroll {
  
    0%   { opacity: 0;}
    50%  { opacity: .5;}
    100% { opacity: 1;}
  }
  @-moz-keyframes mouse-scroll {
  
    0%   { opacity: 0; }
    50%  { opacity: .5; }
    100% { opacity: 1; }
  }
  @-o-keyframes mouse-scroll {
  
    0%   { opacity: 0; }
    50%  { opacity: .5; }
    100% { opacity: 1; }
  }
  @keyframes mouse-scroll {
  
    0%   { opacity: 0; }
    50%  { opacity: .5; }
    100% { opacity: 1; }
  }
  
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.77);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 

  .loading-animation {
    width: 50px;
    height: 50px;
    position: relative;
  }
  
  .loading-animation:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid #e5e5e5;
  }
  
  .loading-animation:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: #007aff;
    animation: fill 1s linear infinite;
  }
  
  @keyframes fill {
    0% {
      transform: rotate(0deg);
      border-top-color: #007aff;
    }
    50% {
      transform: rotate(180deg);
      border-top-color: transparent;
    }
    100% {
      transform: rotate(360deg);
      border-top-color: #007aff;
    }
  }

  .vtk-wait-cursor {
    cursor: wait!important;
  }

  .vtk-progress-cursor {
    cursor: progress!important;
  }